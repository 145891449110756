<template>
    <section class="w-full pt-2 overflow-hidden max-w-[390px] mb-4 lg:mb-8">
        <div class="relative  mb-2 mx-2 p-2 rounded ">
            <h2 style="font-family: sectionTitle;" class="text-[#eac300] text-2xl mb-2 text-center w-full">Au four OU Au moulin ?</h2>
            <p class="text-xs lg:text-sm mb-2">Les meuniers et les boulangers sont les métiers évidents de la farine et du pain. Sont-ils les seuls ? Pas du tout ! Découvrez les métiers moins connus du monde de la meunerie et de la boulangerie !</p>

            <div class="grid lg:grid-cols-4 gap-4">
                <a v-if="this.uni == 'meunerie'" class="rounded-xl overflow-hidden " href="Fiche Acheteur.pdf" download="Fiche Acheteur.pdf">
                    <img src="../../assets/temporaire/Acheteur.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'meunerie'" class="rounded-xl overflow-hidden " href="Fiche Boulanger.pdf" download="Fiche Boulanger.pdf">
                    <img src="../../assets/temporaire/Boulanger d_essai.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'meunerie'" class="rounded-xl overflow-hidden " href="Fiche Chauffeur.pdf" download="Fiche Chauffeur.pdf">
                    <img src="../../assets/temporaire/Chauffeur.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'meunerie'" class="rounded-xl overflow-hidden " href=".pdf" download=".pdf">
                    <img src="../../assets/temporaire/Chef meunier.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'meunerie'" class="rounded-xl overflow-hidden " href="Fiche Commercial.pdf" download="Fiche Commercial.pdf">
                    <img src="../../assets/temporaire/Commercial.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'meunerie'" class="rounded-xl overflow-hidden " href="Fiche Conducteur.pdf" download="Fiche Conducteur.pdf">
                    <img src="../../assets/temporaire/Conducteur.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'meunerie'" class="rounded-xl overflow-hidden " href="Fiche QHSE.pdf" download="Fiche QHSE.pdf">
                    <img src="../../assets/temporaire/QHSE.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>

                
                <a v-if="this.uni == 'boulangerie'" class="rounded-xl overflow-hidden " download="Fiche BoulangerB.pdf">
                    <img src="../../assets/temporaire/Boulanger.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'boulangerie'" class="rounded-xl overflow-hidden " download="Fiche Patissier.pdf">
                    <img src="../../assets/temporaire/Patissier.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'boulangerie'" class="rounded-xl overflow-hidden " download="Fiche Tourier.pdf">
                    <img src="../../assets/temporaire/Tourier.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
                <a v-if="this.uni == 'boulangerie'" class="rounded-xl overflow-hidden " download="Fiche Vendeur.pdf">
                    <img src="../../assets/temporaire/Vendeur.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </a>
               
            </div>
        </div>
    </section>
</template>

<script>



export default {
  name: 'FicherMetier',
  props: {
    uni: String
  },
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
