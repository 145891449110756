<template>
    <section class="w-full pb-2 pt-2 overflow-hidden max-w-[390px] lg:max-w-none">
      <div class="relative bg-white mb-2 h-full mx-2 p-2 rounded">
        <h2 style="font-family: sectionTitle;" class="text-center w-full text-[#eac300] text-2xl mb-2">
          Le moulin du futur ?
        </h2>
        <p class="px-2 mb-2 text-xs lg:text-sm text-center">Les métiers de la meunerie et de la boulangerie sont des métiers passionnants, innovants et en constante évolution. Alors, à quoi ressemblera le moulin français dans 10 ans ?</p>
        <swiper :options="swiperOptions" class="min-h-48">
          <swiper-slide v-for="(video, index) in videos" :key="index">
            <div class="video-area mx-2 overflow-hidden rounded-xl aspect-video">
              <img class="cursor-pointer w-full image-cropped" :src="getThumbnail(video.link)" alt="" @click="playVideo(video.link)">
            </div>
          </swiper-slide>
        </swiper>
        <div class="absolute top-1/2 -translate-y-1/2 left-0 z-10" >
                    <img src="../../assets/images/arrowtri.png" alt="Flèche Gauche" class="w-8 animArrow animArrowleft">
                </div>
                <div class="rotate-180 absolute top-1/2 -translate-y-1/2 right-0 z-10" >
                    <img class="w-8 animArrowleft" src="../../assets/images/arrowtri.png" alt="Flèche Droite">
                </div>
        <h2 style="font-family: sectionTitle;" class="text-[#eac300] text-xl text-center mt-1 lg:text-3xl lg:mt-4">
          Tap pour visionner la vidéo
        </h2>
      </div>
    </section>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/swiper-bundle.css';
  import generateThumbnail from 'youtube-thumbnail';
  const axios = require("axios");
  
  export default {
    name: 'VideosSection',
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      uni: {
        type: String,
        default: "meunerie"
      }
    },
    data() {
      return {
        videos: [],
        swiperOptions: {
          slidesPerView: 1,
          spaceBetween: 10,
          pagination: {
            clickable: true,
          },
          navigation: true,
          loop: true,
        }
      };
    },
    mounted() {
      this.loadVideos();
    },
    methods: {
      loadVideos() {
        axios.get(this.$parent.$parent.$parent.ipReq + "/api/videos/" + this.uni)
          .then(response => {
            this.videos = response.data;
            this.$parent.videos = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },
      getThumbnail(url) {
        return generateThumbnail(url).high.url;
      },
      playVideo(link) {
        this.$parent.watching = link;
      },
    }
  };
  </script>
  
  <style scoped>
  .image-cropped {
  width: 100%; /* ou la largeur désirée */
  height: 100%; /* ou la hauteur désirée */
  object-fit: cover;
}
  </style>
  