<template>
  <div class="app bgg ">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      ipReq : 'https://map.chasseursdegraines.fr',
    }
  },
  components: {
    
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


  .bgg {
    background-color: rgb(255, 255, 255);

  }

  ::-webkit-scrollbar {
    display: none;
}




  


</style>
