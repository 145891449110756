<template>
    <div class="w-full h-full overflow-y-scroll bg-[#1C1C1C] transition-150 appear">
      <div class="flex justify-between w-full">
        <h2 class="mt-4 font-bold text-white ml-2 mb-2">Lecteur Vidéo:</h2>
      </div>
      <div class="bg-black flex justify-around">
        <iframe class="w-full px-4 md:w-1/2 aspect-video cursor-pointer" :src="`https://www.youtube.com/embed/${youtubeVideoId}`" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
      <div class="w-full flex justify-around mt-6 ">
        <button class="bg-red-400 px-12 py-2 rounded-xl" @click="this.$parent.watching = ''">Retour</button>
      </div>
      <span class="flex mt-6 mb-4 mx-3 items-center">
        <span class="h-px flex-1 bg-white"></span>
        <span class="shrink-0 px-6  font-bold text-white">Découvre en plus</span>
        <span class="h-px flex-1 bg-white"></span>
      </span>
      <div class="flex overflow-x-scroll " ref="scrollContainer">
        <div v-for="v in this.$parent.videos" :key="v">
          <div class="h-40 my-1 aspect-video rounded"  v-if="v.link != youtubeVideoLink">
            <img  class="w-full h-full px-1 cursor-pointer image-cropped" :src="this.getminia(v.link)" v-on:click="changeTo(v.link)"/>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-end mb-20">
        <svg class="h-10 mr-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12H20M20 12L16 8M20 12L16 16" stroke="#FFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
      </div>
    </div>
    
  </template>
  
  <script>
  import generate from 'youtube-thumbnail';

  export default {
    name: 'LecteurVideo',
    props: {
      youtubeVideoLink: {
        type: String,
        required: true,
      },
    },
    computed: {
      youtubeVideoId() {
        // Extraire l'identifiant de la vidéo à partir du lien YouTube
        const url = new URL(this.youtubeVideoLink);
        let videoId = url.searchParams.get('v');
  
        // Vérifier si l'identifiant est au format "youtu.be"
        if (!videoId) {
          const path = url.pathname.split('/');
          videoId = path[path.length - 1];
        }
  
        return videoId;
      },
    },

    methods: {
      getminia(link){
        return generate(link).high.url;
      },

      changeTo(link){
        this.$parent.watching = link;
        this.resetScroll();
      },
      resetScroll() {
        if (this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollTo(0, 0);
        }
      },
    }
    
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.appear{
      animation: Apparition 0.15s ease-in-out forwards;
    }
    @keyframes Apparition {
      0%{
        opacity: 0;
      }
      100%{
        opacity: 100%;
      }
    }
    .image-cropped {
  width: 100%; /* ou la largeur désirée */
  height: 100%; /* ou la hauteur désirée */
  object-fit: cover;
}
</style>
  