<template>
    <div class="flex h-[100vh] flex-col justify-center px-6 py-12 lg:px-8 bg-blue-50">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-10 w-auto" src="../assets/icon/moulin.png" alt="Your Company">
            <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Administration</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div class="space-y-6" action="#">  
            <div>
                <label class="block text-sm font-medium leading-6 text-gray-900">Identifiant</label>
                <div class="mt-2">
                <input v-model="username" required class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
                </div>
            </div>

            <div>
                <div class="flex items-center justify-between">
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                </div>
                <div class="mt-2">
                <input v-model="pdw" id="password" name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6 px-2">
                </div>
            </div>

            <div>
                <button v-on:click="login()" class="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Sign in</button>
            </div>
            </div>
            <br><br><br>
    
        </div>
    </div>
  </template>
  
  <script>
import router from "@/router";

    const axios = require("axios")
  export default {
    name: 'LoginView',
    components: {
      
    },
    data() {
        return{
            username: '',
            pdw: ''
        }
    },

    
    

    methods: {
        async login() {
      try {
        const response = await axios.post(this.$parent.$parent.ipReq + '/api/user/login', {
          username: this.username,
          password: this.pdw,
        });

        // Assuming your server sends back the user and token in the response
        const { user, authToken } = response.data;

        // Save the token in the local storage
        localStorage.setItem('authToken', authToken);

        router.push("/admin/meunerie")

        // Now you can use the user and token as needed in your component
        console.log('User:', user);
        console.log('Token:', authToken);
      } catch (error) {
        console.error('Error during login:', error);
      }
    },
    }
  }
  </script>
  