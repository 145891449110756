import { createRouter, createWebHistory } from 'vue-router'
import LandingView from '../views/LandingView.vue'
import AdminMeunerie from '../views/AdminMeunerie.vue'
import AdminBoulangerie from '../views/AdminBoulangerie.vue'
import LoginView from '../views/LoginView.vue'
import AdminVideoView from '../views/AdminVideoView.vue'
import MeunerieView from '../views/MeunerieView.vue'
import BoulangerieView from '../views/BoulangerieView.vue'
import ImgView from '../views/ImgView.vue'
import AdminMetier from '../views/AdminMetier.vue'
import MapView from '../views/MapView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingView
  },
  {
    path: '/meunerie',
    name: 'meunerie',
    component: MeunerieView
  },
  {
    path: '/boulangerie',
    name: 'boulangerie',
    component: BoulangerieView
  },
  {
    path: '/admin/meunerie',
    name: 'admMeunerie',
    component: AdminMeunerie,
    
  },
  {
    path: '/admin/boulangerie',
    name: 'admBoulangerie',
    component: AdminBoulangerie,
    
  },
  {
    path: '/admin/video-edit',
    name: 'video',
    component: AdminVideoView,
    
  },
  {
    path: '/admin/img-edit',
    name: 'img',
    component: ImgView,
    
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/admin/metier-edit',
    name: 'metier',
    component: AdminMetier
  },
  {
    path: '/map/:uni',
    name: 'map',
    component: MapView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
