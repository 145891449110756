<template>
    <div class="w-full">
        <div class="flex h-full aspect-square cursor-pointer items-center justify-center rounded-xl bg-white p-2 bg-[#eac300]">
            <div class="space-y-2">
                <span class="block h-1 w-6 origin-center rounded-full bg-[#eac300] transition-transform ease-in-out" :class="{'translate-y-1.5 rotate-45': clicked}"></span>
                <span class="block h-1 w-4 origin-center rounded-full bg-[#eac300] transition-transform ease-in-out" :class="{'-translate-y-1.5 -rotate-45 w-6': clicked}"></span>
            </div>
        </div>
    </div>
</template>
    

<script>

export default {
components: {

},
props: {
  clicked: {
    type: Boolean,
    default: false
  },
  uni: {
    type: String,
    default: "BurgerBtn"
  }
},
data() {
  return {
  }
},
mounted() {
    
  
},
methods: {
   
}
};
</script>

<style>





</style>
