<template>
  <div class="lg:hidden min-h-[42rem] w-screen overflow-x-hidden flex justify-around bg">
    <div class="relative min-w-[23rem] max-w-[23rem] min-h-screen w-screen">
        <div class="h-[22%] w-full flex justify-around absolute -top-4">
            <img class="py-4 z-20 logolanding" src="../assets/images/logo-landing.png" alt="">
        </div>

        <div class="h-3/4 w-[82%] ml-[9%] bg-white/40 border border-black beff overflow-hidden pt-16 mt-32 lg:hidden">
          <div class="h-1/2" v-on:click="this.$router.push('/meunerie')">
            <h2 class="TitleFont m-0">MEUNERIE</h2>
            <img class="px-12 m-0" src="../assets/landing/image_haut.png" alt="">
          </div>
          <div v-on:click="this.$router.push('/boulangerie')" class="h-1/2 pt-6 mb-12">
            <img class="px-12 m-0"  src="../assets/landing/image_bas.png" alt="">
            <h2 class="TitleFont">BOULANGERIE</h2>
          </div>
        </div>
        <div  class="bandeau z-20 w-full absolute top-[52%]  h-12 -translate-y-3 text-center lg:hidden">
          <h3 class="h-full flex flex-col justify-around choose text-xl">Choisis ton univers !</h3>
        </div>
        <img src="../assets/landing/fleche_bas.png" class="absolute top-1/2 h-20 right-16 lg:hidden" alt="">
        <img src="../assets/landing/fleche_haut.png" class="absolute top-1/2 h-20 left-16 -translate-y-14 lg:hidden" alt="">
        <div class="flex mx-12 lg:hidden">
          <img src="../assets/landing/footer_logo_anmf.png" class="w-5" alt="">
          <p class="text-gray-800 text-[10px] text-center mt-3">Site édité par l'ANMF, Association Nationale de la Meunerie Française</p>
        </div>
       
    </div>  

  </div>

  <div class="h-full w-full bgPc max-h-[100vh] overflow-hidden hidden lg:block">
    <div class="h-1/4 flex w-full justify-around">
      <img class="py-4 z-20 h-full" src="../assets/images/logo-landing.png" alt="">
    </div>
    <div class="flex flex-col  justify-around h-2/3 w-full px-16 min-w-80">
      <div class="flex justify-around w-full">
        <div class="h-full w-1/3 flex justify-around">
          <div v-on:click="this.$router.push('/meunerie')" class="">
            <img class="w-[40vh] cursor-pointer" src="../assets/landing/image_haut.png" alt="">
            <h2 class="TitleFont">MEUNERIE</h2>
          </div>
        </div>
        <div class="w-1/3 flex flex-col justify-around relative">
          <div  class="w-full h-14 text-center rounded-xl overflow-hidden">
            <h3 class="h-full flex flex-col justify-around choose text-xl bg-[#bfad95] text-italic">Choisis ton univers !</h3>
          </div>
          <img src="../assets/landing/fleche_haut.png" class="rotate-[53deg] right-9 w-16 top-8  absolute" alt="">
          <img src="../assets/landing/fleche_haut.png" class="-rotate-[130deg] left-9 w-16 bottom-8  absolute" alt="">
        </div>
        <div class="h-full w-1/3 flex justify-center">
          <div v-on:click="this.$router.push('/boulangerie')">
            <img class="w-[40vh] cursor-pointer" src="../assets/landing/image_bas.png" alt="">
            <h2 class="TitleFont h-1/3">BOULANGERIE</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
    
  </template>
  
  <script>

  export default {
    name: 'LandingView',
    components: {
      
    },
    data() {
        return{
            
        }
    },

    mounted(){
     
    }
    

    
  }
  </script>

  <style scoped>


  .bgg::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@font-face {
    font-family: 'LeagueGothic-Regular';
    src: url('../assets/fonts/LeagueGothic-Regular.otf') format('opentype');   /* Format WOFF */
    /* Ajoutez des formats supplémentaires si nécessaire */

}

@font-face {
    font-family: 'Rosaline Signature';
    src: url('../assets/fonts/Rosaline\ Signature.ttf') format('truetype');   /* Format WOFF */
    /* Ajoutez des formats supplémentaires si nécessaire */

}


@font-face {
    font-family: 'TitreMetier';
    src: url('../assets/fonts/TYPOGRAPHIE/Papernotes.otf') format('opentype');   /* Format WOFF */
    /* Ajoutez des formats supplémentaires si nécessaire */

}

@font-face {
    font-family: 'Choose';
    src: url('../assets/fonts/TYPOGRAPHIE/Lato-Bold.ttf') format('truetype');   /* Format WOFF */
    /* Ajoutez des formats supplémentaires si nécessaire */

}

.bgPc{
  background-image: url('../assets/images/backgroundpc.png');
  background-size: cover; /* Redimensionne l'image pour couvrir l'intégralité de l'écran */
  background-position: center center; /* Centre l'image */
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.bandeau{
  font-style: italic;
  background-color: #bfad95;
  transform: translateY(-50%);
}

.beff{
  transform: translateY(-8%);
}

.bg{
  background-image: url('../assets/images/background.png');
  background-size: cover; /* Redimensionne l'image pour couvrir l'intégralité de l'écran */
  background-position: center center; /* Centre l'image */
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.logolanding{
  transform: scale(1.1);
}

.TitleFont{
  font-family: 'TitreMetier';
  font-size: 2.5rem;
  font-weight: 700;
  color: #000000;
  text-align: center;

}

.choose{
  font-family: 'Choose';
  font-size: 1.5rem;
  font-weight: 800;
}




</style>
  