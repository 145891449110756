<template>
    <div class="pt-20 h-screen overflow-hidden">
        <div class="w-full h-full bg-black relative overflow-hidden">
            <embed :src="this.$parent.selectPdf" class="h-full w-full" type="application/pdf">
                
                <div class="lg:hidden bg-[#eac300] rounded-full absolute top-8 right-8 w-6 aspect-square text-center flex flex-col font-bold justify-around" v-on:click="this.$parent.selectPdf = ''">
                    X
                </div>
                <div class="absolute bottom-0 pb-12 px-20 flex justify-around lg:justify-end w-full">
                    <button class="bg-[#eac300] p-2 rounded-xl w-28" v-on:click="this.$parent.selectPdf = ''">Retour</button>
                </div>
            
        </div>
    </div>
</template>

<script>

export default {
    name: 'PdfReader',
    components: {
        
    },
    props: {
  
    },
    data() {
        return {
        };
    },
};
</script>
