<template>
    <div>
        <div class="text-xs w-36 text-center font-bold mb-4 absolute right-2 top-2 border border-1 border-gray-400 bg-white px-4 rounded flex justify-center">
            <div class="flex h-full py-1">
                <h2 class="font-bold">Etat: </h2>
                <h2 v-if="synchro" class="font-bold text-green-500 ml-2">A jour</h2>
                <h2 v-else class="font-bold text-orange-500 ml-2">Synchronisation</h2>

            </div>
        </div>
      <SideBar></SideBar>
      <link href="https://fonts.googleapis.com/css2?family=Varela+Round&display=swap" rel="stylesheet">
  
      <div class="home min-h-[100vh] ml-16 text-gray-700 pt-8" style="font-family: 'Varela Round', sans-serif;">
        <h1 class="text-3xl font-bold mb-4 ml-4">Administration Images</h1>
        <div class="flex w-[282px] rounded-t text-center overflow-hidden p-0 bg-gray-100 border border-1 border-black ml-4">
            <div class="w-1/2 h-full border-r cursor-pointer" :class="{'bg-orange-200' : this.uni == 'meunerie'}" v-on:click="this.changeUni('meunerie')">Meunerie</div>
            <div class="w-1/2 h-full cursor-pointer" :class="{'bg-orange-200' : this.uni == 'boulangerie'}" v-on:click="this.changeUni('boulangerie')">Boulangerie</div>
        </div>
        <div class="relative flex">
          <div class="flex overflow-y-scroll h-[84vh] min-w-[280px] border-b-2  bg-gray-200 ml-4 px-1  border-gray-500" ref="scrollZone">
            <div class="h-full w-full">
              <p v-if="!this.images.length" class="mt-20 w-full text-center text-gray-500">Aucune image n'a été sauvegardée</p>
              <div v-for="(image, index) in images" :key="image._id" class="bg-white my-2 border border-gray-500 flex rounded overflow-hidden mx-2 flex cursor-pointer hover:transform hover:scale-105 transition duration-300 ease-in-out">
                <div class="flex">
                  <div class="overflow-hidden w-[200px] max-h-40 relative bg-gray-400 flex justify-around">
                    <img :src="this.$parent.$parent.ipReq + image.link" class="bg-gray-500 h-full" />
                  </div>
                </div>
                <div class="flex flex-col justify-between py-2 px-1">
                <svg v-on:click="changePlace(index, -1)" v-if="index != 0" viewBox="0 0 24 24" class="w-full h-12" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 11L12 8M12 8L9 11M12 8V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <div v-else class="w-full h-12"></div>
                <svg v-on:click="deleteImage(image._id)" class="p-2 w-full h-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <svg v-on:click="changePlace(index, +1)" v-if="index != this.images.length-1" class="w-full h-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 13L12 16M12 16L15 13M12 16V8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <div v-else class="w-full h-12"></div>
              </div>
              </div>
            </div>
          </div>
          <div class="w-7/12">
            <div class="ml-12 w-full bg-white p-4 border border-gray-500 rounded-xl">
              <h2 class="text-xl font-bold">Ajouter une image</h2>
              <div class="w-full mt-6">
                <div class="mt-12">
                    <input type="file" ref="inputfile" accept="image/*" @change="handleImageUpload " />
                </div>
                <div class="mt-12">
                    <p><strong>Formats autorisés:</strong> .png .jpg .gif</p>
                </div>
              </div>
              <div class="flex justify-around mt-4 min-h-12">
                <p v-if="msgnotif == 1" class="text-green-500 font-bold">Image ajoutée avec succès !</p>
                <p v-if="msgnotif == 2" class="text-red-500 font-bold">Erreur lors de l'ajout de l'image</p>
                <p v-if="msgnotif == 4" class="text-orange-500 font-bold">Traitement de l'image</p>

                </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loaded" id="loader" class="flex justify-center pt-80 top-0 w-[100vw] -mt-8 bg-black/60 z-[1000] absolute h-[110vh]">
              <div class="text-center">
                <span class="loader"></span>
                <h1 class="font-bold text-white">Chargement des informations...</h1>
              </div>
            </div>
  </template>
  
  <script>
  import SideBar from "@/components/SideBar.vue";
  const axios = require("axios");
  
  export default {
    name: "ImgView",
    components: {
      SideBar,
    },
    data() {
      return {
        images: [],
        tokenn: localStorage.getItem("authToken"),
        msgnotif: 0,
        synchro: false,
        uni: "meunerie",
        loaded: false

      };
    },
    mounted() {
      this.loadImages();
    },
    methods: {

        async changeImageOrder(imageId1, imageId2) {
            console.log("Changement d'ordre des images:", imageId1, imageId2)
    try {
        this.synchro = false;

      await axios.patch(this.$parent.$parent.ipReq + '/api/image/order', {
        id1: imageId1,
        id2: imageId2,
        univers: this.uni
      }, {
        headers: {
          Authorization: this.tokenn
        }
      });
      this.loadImages(); 
        this.synchro = true;
    } catch (error) {
      console.error("Erreur lors du changement d'ordre des images:", error);
    }
  },

  changeUni(uni){
      this.uni = uni;
      this.loadImages();
  },

  async deleteImage(imageId) {
    try {
        this.synchro = false;
      await axios.delete(this.$parent.$parent.ipReq + `/api/image/${imageId}`, {
        headers: {
          Authorization: this.tokenn
        }
      });
      this.loadImages();
      this.synchro = true;
    } catch (error) {
      console.error("Erreur lors de la suppression de l'image:", error);
    }
  },
  changePlace(index, direction) {
    const currentImageId = this.images[index]._id;
    const targetImageId = this.images[index + direction]._id;
    this.changeImageOrder(currentImageId, targetImageId);
  },
      async loadImages() {
        try {

          const response = await axios.get(this.$parent.$parent.ipReq + "/api/images/" + this.uni);
          this.images = response.data;
          this.synchro = true;
          if(this.loaded == false){
            this.loaded = true;
          }

        } catch (error) {
          console.error("Error loading images:", error);
        }
      },
      async handleImageUpload(event) {
  try {
    this.synchro = false;
    this.msgnotif = 4;
    console.log("Notification:", this.msgnotif);

    const file = event.target.files[0];
    console.log("File:", file);

    const formData = new FormData();
    formData.append("image", file);
    formData.append("univers", this.uni); // Assurez-vous d'avoir un champ ou une propriété pour sélectionner l'univers

    const response = await axios.post(this.$parent.$parent.ipReq + "/api/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: this.tokenn,
      },
    });

    const newImage = response.data;
    this.images.push(newImage);
    this.msgnotif = 1;
    this.synchro = true;

    setTimeout(() => {
      this.msgnotif = 0;
    }, 5000);
    this.$refs.inputfile.value = '';

  } catch (error) {
    this.$refs.inputfile.value = '';

    console.error("Error uploading image:", error);
    this.msgnotif = 2;

    setTimeout(() => {
      this.msgnotif = 0;
    }, 5000);

    this.synchro = true;
  }
},
    },
  };
  </script>
  
  <style scoped>
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
  </style>
