<template>
  <div>
    <MapHover :uni="$route.params.uni" view="pc" class="" />
  </div>
</template>

<script>
import MapHover from '../components/hovers/MapHover2.vue';

export default {
  name: 'MapView',
  components: {
    MapHover,
  },
  data() {
    return {
      // Your data properties here
    };
  },
  methods: {
    // Your methods here
  },
  mounted() {
    console.log('Component mounted.' + this.$route.params.uni)
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
