<template>
        <div class="bg-white min-h-24 z-[2005] fixed top-0 h-[10vh] w-screen flex justify-around  p-0 m-0">
            <div class="flex justify-around lg:justify-between w-1/3 hidden sm:flex">
                <div class="w-40 flex flex-col justify-around ">
                    <img src="../assets/images/logo-anmf.png" class="" alt="">
                </div>
                <img class="py-6 hidden lg:block" src="../assets/landing/mascotte-2.png" alt="">
                <img class="py-5 hidden lg:block" src="../assets/landing/mascotte-4.png" alt="">
            </div>
            <div class="flex justify-around">
                <img src="../assets/images/mainLogo.png" class="h-full ml-4 lg:ml-0" alt="">
            </div>
            <div class="flex justify-end lg:justify-between w-1/3 overflow-hidden">
                <img class="py-5 hidden lg:block" style="transform: scaleX(-1);" src="../assets/landing/mascotte-2.png" alt="">
                <img class="py-6 hidden lg:block" style="transform: scaleX(-1);" src="../assets/landing/mascotte-3.png" alt="">
            <div class="w-1/2 h-full">
                <div class="h-full mt-2 flex flex-col justify-around mr-12 lg:mr-0 ">
                    <burger-btn v-on:click="this.inMenu = !this.inMenu" :clicked="inMenu"/>
                </div>
            </div>
        </div>
    </div>


            <div v-if="inMenu" v-on:click="this.inMenu = false" class="z-[1005] bg-black/30 h-screen w-screen fixed transition-all"></div>
            <div class="pt-2 overflow-hidden fixed text-center -mt-2 z-[1005] bg-gray-100 border border-b-2 border-[#eac300] w-screen -translate-y-44  transition-all" :class="{'translate-y-0 transition-all  transition-100': this.inMenu}">
                <div class="my-1">
                    <a href="#" v-on:click="this.inMenu = false" class="text-[#eac300] font-bold my-1 cursor-pointer">Infographie</a>
                </div>
                <div class="my-1">
                    <a href="#" v-on:click="this.inMenu = false; this.watching = this.videos[0].link" class="text-[#eac300] font-bold my-1 cursor-pointer">Vidéos</a>
                </div>
                <div class="my-1">
                    <a href="#" v-on:click="this.inMenu = false;" class="hidden lg:block text-[#eac300] font-bold my-1 cursor-pointer">Visite un moulin</a>
                    <a href="#" v-on:click="this.inMenu = false; this.displayMap = true" class="lg:hidden cd text-[#eac300] font-bold my-1 cursor-pointer">Visite un moulin</a>
                </div>
                <div class="my-1">
                    <a href="#metier" v-on:click="this.inMenu = false;" class="text-[#eac300] font-bold my-1 cursor-pointer">Métiers</a>
                </div>
                <div v-on:click="this.$router.push('/')" class="flex justify-around text-white  font-bold my-1 cursor-pointer">
                    <h2 class="bg-[#eac300] w-60 rounded flex flex-col justify-around h-8 mb-2">Change d'univers</h2>
                </div>
            </div>
            <LecteurVideo class="fixed top-0 mt-24 z-[1004]" :youtubeVideoLink="this.watching" v-if="this.watching"/>
            <div v-if="displayMap" class="fixed mt-20 z-20 bg-white top-0 h-screen w-screen"> 
                <MapHover uni="meunerie" class="" />
            </div>
            
            <div class="flex justify-around relative mt-24 ">
                <section class="w-full pt-2 lg:max-w-full relative lg:mx-12 max-w-[390px]" ref="swipeArea">
                    <div class="relative bg-white mb-2 h-full mx-2 p-2 rounded">
                        <p class="text-xs lg:text-sm">Bienvenue sur <strong class="text-[#eac300]">ChasseursDeGraines.fr</strong>, le site de la meunerie française qui met en avant les métiers de la meunerie et de la boulangerie ! <br/>
De la farine à la baguette, découvrez tous les secrets des métiers passionnants de la meunerie et de la boulangerie et surtout, toutes les opportunités professionnelles des métiers de la farine.
<br/>Visites, stages, alternances, CDD ou CDI, l’univers de la farine à tout à vous offrir !
</p>
                    </div>
                </section>
            </div>

            <div class="flex justify-around relative lg:hidden">
                <swiper uni="meunerie"></swiper>
            </div>

            <div class="flex justify-around w-full relative lg:hidden">
                <VideosSection uni="meunerie"/>
            </div>
            <div class="flex justify-around w-full lg:hidden">
                <MapSection/>
            </div>
            <section id="metier" class="flex justify-around w-full lg:hidden">
                <FicheMetier uni="meunerie"/>
            </section>
        <div class="hidden lg:block">
            <LecteurVideo class="fixed top-0 z-20" :youtubeVideoLink="this.watching" v-if="this.watching"/>
            <div class="flex justify-around mx-12">
                <div class="w-1/2">
                    <swiper uni="meunerie"></swiper>
                </div>
                <div class="w-1/2 relative">
                    <VideosSection uni="meunerie"/>
                    <div class="flex justify-around">
                        <img src="../assets/landing/mascotte-3.png" class="h-20 ml-12" alt="">
                        <img src="../assets/landing/mascotte-4.png" style="transform: scaleX(-1);" class="h-20 ml-12" alt="">
                    </div>
                </div>

            </div>
            <section id="mapsection" class="bg-white mt-2 rounded min-h-[600px] mx-12">
                    <div class="mx-12 pt-2 ">
                        <h2 style="font-family: sectionTitle;" class="text-center w-full text-[#eac300] text-2xl mt-2">Prenez RDV !</h2>
                        <p class="text-center text-sm">Prêts à vous lancer dans l’aventure de la boulangerie, retrouve la liste des centres de formation pour devenir boulangers mais pas que !</p>
                    </div>
                    <div class="">
                        <MapHover v-if="!watching" uni="meunerie" view="pc" class="w-full pb-4" />
                    </div>
            </section>
            
        </div>
        <div class="flex justify-around w-full">

        <div class="bg-white p-4 mx-12 mt-2  overflow-hidden rounded-xl hidden lg:block">
            <h2 style="font-family: sectionTitle;" class="text-center w-full text-[#eac300] text-2xl mb-2">Au four OU au moulin ? </h2>
            <p class="text-xs lg:text-sm mb-2 text-center">Les meuniers et les boulangers sont les métiers évidents de la farine et du pain. Sont-ils les seuls ? Pas du tout ! Découvrez les métiers moins connus du monde de la meunerie et de la boulangerie !</p>

            <div class="grid lg:grid-cols-4 gap-4">
                <div class="rounded-xl overflow-hidden " @click="selectPdf = 'Fiche Acheteur.pdf'">
                    <img src="../assets/temporaire/Acheteur.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </div>
                <div class="rounded-xl overflow-hidden " @click="selectPdf = 'Fiche Boulanger.pdf'">
                    <img src="../assets/temporaire/Boulanger d_essai.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </div>
                <div class="rounded-xl overflow-hidden " @click="selectPdf = 'Fiche Chauffeur.pdf'">
                    <img src="../assets/temporaire/Chauffeur.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </div>
                <div class="rounded-xl overflow-hidden ">
                    <img src="../assets/temporaire/Chef meunier.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </div>
                <div class="rounded-xl overflow-hidden " @click="selectPdf = 'Fiche Commercial.pdf'">
                    <img src="../assets/temporaire/Commercial.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </div>
                <div class="rounded-xl overflow-hidden " @click="selectPdf = 'Fiche Conducteur.pdf'">
                    <img src="../assets/temporaire/Conducteur.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </div>
                <div class="rounded-xl overflow-hidden " @click="selectPdf = 'Fiche QHSE.pdf'">
                    <img src="../assets/temporaire/QHSE.png" class="hover:scale-110 transition-transform duration-300" alt="">
                </div>
               
            </div>
        </div>  
           
        
    </div>
    <div class="m-0 lg:mt-8 h-32 w-full pt-3 bg-white relative" >
        <div class="absolute w-screen h-full">
            <h2 style="font-family: sectionTitle;" class="text-[#eac300] text-2xl mb-2 text-center z-[10]">- Contacts -</h2>
            <p class="text-center text-sm">Une question ? Un souci ? Contactez l’ANMF !</p>
            <div class="flex justify-around w-full mt-2">
                <div class="justify-around flex max-w-[400px]">
                    <p class="mx-6">01.43.59.45.80</p>
                    <p class="mx-6">anmf@glaboetie.org</p>
                </div>
            </div>
        </div>
        <div class="w-screen flex h-40 z-[1]">
            <img class="h-full overflow-hidden" src="../assets/images/footer_bg.png" alt="" style="background-color: transparent;">
            <img class="hidden lg:block h-16 mt-24" src="../assets/images/Logo_CNBPF.png" alt="">
            <img class="hidden lg:block h-20 mt-20" src="../assets/images/logo-anmf.png" alt="">
            <img class=" hidden lg:block h-16 mt-24" src="../assets/images/Logo_FEB.png" alt="">
        </div>
        
    </div>  
    <PdfReader v-if="selectPdf" class="fixed top-0 z-20 bg-white h-screen w-screen"></PdfReader>

</template>

<script>
import VideosSection from '../components/sections/VideosSection.vue'
import LecteurVideo from '../components/hovers/LecteurVideo.vue'
import MapSection from '../components/sections/MapSection.vue'
import MapHover from '../components/hovers/MapHover.vue'
import swiper from '../components/sections/CarrouComp.vue'
import FicheMetier from '../components/sections/FicherMetier.vue'
import burgerBtn from '../components/others/BurgerBtn.vue'
import PdfReader from '../components/hovers/PdfReader.vue'
// @ is an alias to /src
export default {
name: 'MeunerieView',
components: {
    VideosSection,
    LecteurVideo,
    MapSection,
    MapHover,
    swiper,
    PdfReader,
    FicheMetier,
    burgerBtn
    
},
data() {
    return {
        watching: "",
        displayMap: false,
        images: [],
        videos: [],
        inMenu: false,
        selectPdf: ""
        
        
    };
},


mounted(){
    console.log("Mounted")
    
},

methods: {
    
    
}
}
</script>


<style scoped>



@font-face {
    font-family: 'sectionTitle';
    src: url('../assets/fonts/LeagueGothic-Regular.otf') format('opentype');   /* Format WOFF */
    /* Ajoutez des formats supplémentaires si nécessaire */

}
.bgg {
  background-size: cover; /* Redimensionne l'image pour couvrir l'intégralité de l'écran */
  background-position: center center; /* Centre l'image */


}


.bgg::before{
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

@font-face {
  font-family: 'LeagueGothic-Regular';
  src: url('../assets/fonts/LeagueGothic-Regular.otf') format('opentype');   /* Format WOFF */
  /* Ajoutez des formats supplémentaires si nécessaire */

}

@font-face {
  font-family: 'Rosaline Signature';
  src: url('../assets/fonts/Rosaline\ Signature.ttf') format('truetype');   /* Format WOFF */
  /* Ajoutez des formats supplémentaires si nécessaire */

}



.battement{
    animation: battement 3s infinite;
}

@keyframes battement {
    0% {
        transform: scale(1);
        transform: scaleX(-1);
    }
    50%{
        transform: scale(1.4);
        transform: scaleX(-1);
    } 
    100%{
        transform: scale(1);
        transform: scaleX(-1);

    }
    
}

</style>

