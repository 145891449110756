<template>
        <section class="w-full h-full pt-2 max-w-[390px] lg:max-w-none lg:w-full relative" ref="swipeArea">
            <div class="relative mb-2 h-full mx-2 p-2 rounded">
                <h2 style="font-family: sectionTitle;" class="text-[#eac300] text-2xl mb-2 text-center w-full">Le saviez-vous ? </h2>
                <p class="px-2 mb-2 text-xs lg:text-sm text-center">Avec la farine, tout est possible ! Facts, infos surprenantes et infographies, les métiers de la meunerie et de la boulangerie regorgent de secrets… Cliquez-ici pour les découvrir !</p>
                <div class="swiper-container px-2 ">
                <swiper :options="swiperOptions" ref="mySwiper" class="min-h-72">
                    <swiper-slide v-for="(slide, index) in slides" :key="index" >
                        <img :src="slide.link" alt="Slide Image" class="rounded-xl ">
                    </swiper-slide>
                </swiper>
                <div class="absolute top-1/2 -translate-y-1/2 left-0 z-10" >
                    <img src="../../assets/images/arrowtri.png" alt="Flèche Gauche" class="w-8 animArrow animArrowleft">
                </div>
                <div class="rotate-180 absolute top-1/2 -translate-y-1/2 right-0 z-10" >
                    <img class="w-8 animArrowleft" src="../../assets/images/arrowtri.png" alt="Flèche Droite">
                </div>

                </div>
            </div>
            <img class="absolute bottom-0 h-12 animswipe z-[19]" src="../../assets/images/hand.png" alt="">

        </section>
    </template>
        
  
  <script>
  const axios = require("axios");
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/swiper-bundle.css';
  
  export default {
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      uni: {
        type: String,
        default: "meunerie"
      }
    },
    data() {
      return {
        swiperOptions: {
          slidesPerView: 1,
          spaceBetween: 10,
          pagination: {
            clickable: true,
          },
          navigation: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: true,
          },
          loop: true,
        },
        slides: [] 
      };
    },
    mounted() {
     

        axios
          .get(this.$parent.$parent.$parent.ipReq + "/api/images/" + this.uni)
          .then(async (response) => {
            this.slides = response.data;
            this.slides.sort((a, b) => a.order - b.order);
            this.slides.forEach((image) => {
              image.link = this.$parent.$parent.$parent.ipReq + image.link;
            });
            console.log(this.slides)
          })
          .catch((error) => {
            console.log(error);
          });
      
    },
    methods: {
      goNext() {
            console.log(this.$refs.mySwiper)
        },
        goPrev() { 
            this.$refs.mySwiper.slidePrev();
        }
    }
  };
  </script>
  
  <style>
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .animArrowleft {
    animation: slideInFromLeft 2s infinite ease-in-out;
  }



  @keyframes slideInFromLeft {
    0% {
      transform: translateX(0%);
    }

    50% {
      transform: translateX(-10%);
    }

    100% {
      transform: translateX(0);
    }
  }


  .animswipe{
    animation: swipe 6s;
    animation-delay: 2s;
    left: 85%;
    opacity: 0%;

}

.arrowappear{
    animation: arrowappear 6s;
    opacity: 100%;
}


@keyframes arrowappear {
    0% {
        opacity: 0;
    }
    95%{
        opacity: 0;
    } 
    100%{
        opacity: 100%;
    }
    
}

@keyframes swipe {
    0% {
        opacity: 100%;
        transform: translateY(0);
        left: 85%;
    }
    15%{
        transform: translateY(-10px);
        rotate: -10deg;
        left: 45%;

    }
    25%{
        left: 90%;
    }
    38%{
        rotate: 0deg;
        left: 90%;
    }
    45% {
        transform: translateY(0);
        left: 85%;
    }

    48% {
        transform: translateY(0);
        left: 85%;
    }

    65%{
        transform: translateY(-10px);
        rotate: 43deg;
        left: 80%;

    }

    70%{
        transform: translateY(-15px);
        left: 60%;
        opacity: 100%;

    }
    95%{
        opacity: 100%;
    } 
    100%{
        opacity: 0%;
        transform: translateY(0);
        left: 85%;
    }
   
    
}

  </style>
  