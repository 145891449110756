<template>
    <section class="w-full pt-2 overflow-hidden max-w-[390px]" ref="swipeArea">
                    <div class="relative mb-2 h-full mx-2 p-2 rounded" @click="this.$parent.displayMap = true">
                        <h2 style="font-family: sectionTitle;" class="text-[#eac300] text-2xl mb-2 text-center w-full">Prenez RDV !</h2>
                        <div class="p-4">
                            <img class="rounded-xl" src="../../assets/images/map.png" alt="">
                        </div>
                        <div class="flex justify-around">
                            <button  class="bg-yellow-400 px-12 py-2 rounded-xl">Explorer</button>
                        </div>
                    </div>
                </section>
</template>

<script>

export default {
  name: 'VideosSection',
  props: {
  },
  data(){
    return {
        
    }
  },


  

mounted() {
   

  },

  methods: {


   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map{
    clip-path: polygon(0 12%, 100% 12%, 100% 88%, 0 88%);

}
</style>
