<template>
    <div>
        <div class="text-xs w-36 text-center font-bold mb-4 absolute right-2 top-2 border border-1 border-gray-400 bg-white px-4 rounded flex justify-center">
            <div class="flex h-full py-1">
                <h2 class="font-bold">Etat: </h2>
                <h2 v-if="synchro" class="font-bold text-green-500 ml-2">A jour</h2>
                <h2 v-else class="font-bold text-orange-500 ml-2">Synchronisation</h2>

            </div>
        </div>
    <SideBar></SideBar>
    <link href="https://fonts.googleapis.com/css2?family=Varela+Round&display=swap" rel="stylesheet">
    <div class="home min-h-[100vh] h-[100vh] ml-20 text-gray-700 pt-8" style="font-family: 'Varela Round', sans-serif;">
        
        <h1 class="text-3xl font-bold mb-4">Administration Fiches Metiers</h1>

        <div class="flex" style="font-family: 'Varela Round', sans-serif;">
            <div class="w-5/6 h-full bg-white h-full rounded border border-gray-200 p-2 mr-4">
                <h2 class="font-bold text-xl mb-4">Ajouter une fiche</h2>

                <div class=" mt-4 mb-4">
                    <label for="title" class="block text-xs font-medium text-gray-700"> Upload Image de Metier </label>
                    <div class="flex border border-gray-300 rounded">
                        <div class="h-40 w-64 bg-gray-200 border border-black rounded">
                            <img v-if="imagePreview" class="h-full w-full" :src="imagePreview" alt="">
                        </div>
                        <div class="ml-4 flex flex-col pt-4 ">
                            <input type="file" @change="setImage" ref="imageInput" id="imageInput" class="mt-1 w-full sm:text-sm mb-2"/>
                            <h2 class="mt-2 text-sm">Upload: Format (1080px * 886px) - PNG/JPG/GIF</h2>
       
                        </div>
                    </div>
                </div>
                <div class="">
                    <label for="title" class="block text-xs font-medium text-gray-700"> Upload PDF Fiche Metier </label>
                    <div class="rounded pl-4 flex flex-col pt-4 border border-gray-300">
                        <input ref="pdfInput" type="file" id="title" class="mt-1 w-full sm:text-sm mb-2"/>
                        <h2 class="text-sm mb-2">Upload: Fichier .PDF uniquement</h2>
                    </div>
                </div>

                <div>
                    <label for="title" class="block text-xs font-medium text-gray-700"> Univers </label>
                    <div class="rounded pl-4 flex flex-col pt-4 border border-gray-300">
                        <select v-model="univers" class="mt-1  sm:text-sm mb-2 h-12 mx-2 px-4 border border-gray-200 ">
                            <option value="">Selectionner un univers</option>
                            <option value="meunerie">Meunerie</option>
                            <option value="boulangerie">Boulangerie</option>
                        </select>
                    </div>
                </div>

                <div class="flex justify-around w-full mt-4">
                    <button class="bg-green-200 rounded w-full border border-green-800 h-8 text-green-600" @click="this.addMetier()">Sauvegarder</button>
                </div>
            </div>
            <div v-if="loaded" class="w-1/6 max-h-[90vh] overflow-auto">
                <div v-for="f in this.ficheMeunerie" :key="f">
                    <div class="relative my-2">
                        <img :src="this.$parent.$parent.ipReq + '/' + f.image" alt="">
                        <div class="rounded absolute bg-black/70 opacity-0  transition-all hover:opacity-100 top-0 left-0 w-full h-full">
                            <h3 class="font-bold text-white text-center mt-12 ">{{ f.titre }}</h3>
                            <div class="flex mx-12 mt-6 text-center justify-around">
                                
                                <svg class="w-8 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#FFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                
                                <svg class="w-8 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios');
import SideBar from "@/components/SideBar.vue";
export default {
name: 'AdminMetier',
components: {
    SideBar
},

data() {
    return {
        synchro: true,
        ficheMeunerie: [],
        loaded: false,
        imagePreview: null,
        univers: ""

    };
},

mounted(){
    this.getFicheMetier();
},

 beforeCreate() {
    const token = localStorage.getItem('authToken');

     axios.get(this.$parent.$parent.ipReq + '/api/user/verif', {
        headers: {
            Authorization: `${token}`
        }
        })
        .then(() => {
            console.log("logged");
        })
        .catch(() => {
            this.$router.push("/login");
        });

    },

methods: {
    setImage(event) {
        console.log(event.target.files[0])
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                this.imagePreview = URL.createObjectURL(file);
                console.log(this.imagePreview);
            }
        },
    getFicheMetier(){
        axios.get(this.$parent.$parent.ipReq + '/api/metiers/meunerie')
        .then((response) => {
            this.ficheMeunerie = response.data;
            this.loaded = true;
        })
        .catch((error) => {
            console.log(error);
        })
    },

    addMetier(){
        if (!this.univers) {
            alert("Veuillez selectionner un univers");
            return;
        }
            const formData = new FormData();
            // Assuming you have refs 'imageInput' and 'pdfInput' on your file inputs
            const imageFile = this.$refs.imageInput.files[0];
            const pdfFile = this.$refs.pdfInput.files[0];
            formData.append('image', imageFile);
            formData.append('pdf', pdfFile);
            // Append other form data
            formData.append('univers', this.univers); // Ensure you have data property for univers

            const token = localStorage.getItem('authToken');

            axios.post(this.$parent.$parent.ipReq + '/api/metiers', formData, {
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data',
                    // Include other headers like Authorization if needed
                }
            })
            .then(response => {
                console.log(response.data);
                // Handle success, maybe clear the form or show a success message
            })
            .catch(error => {
                console.error("There was an error uploading the file", error);
                // Handle error, show error message to the user
            });
        }

}

}
</script>

<style scoped>

</style>
