<template>
  <div>
    <div class="text-xs w-36 text-center font-bold mb-4 absolute right-2 top-2 border border-1 border-gray-400 bg-white px-4 rounded flex justify-center">
            <div class="flex h-full py-1">
                <h2 class="font-bold">Etat: </h2>
                <h2 v-if="synchro" class="font-bold text-green-500 ml-2">A jour</h2>
                <h2 v-else class="font-bold text-orange-500 ml-2">Synchronisation</h2>

            </div>
        </div>
    <SideBar></SideBar>
    <link href="https://fonts.googleapis.com/css2?family=Varela+Round&display=swap" rel="stylesheet">

    <div class="home min-h-[100vh] ml-16 text-gray-700 pt-8" style="font-family: 'Varela Round', sans-serif;">
      <h1 class="text-3xl font-bold mb-4 ml-4">Administration Vidéos</h1>
      
      <div class="flex w-[375px] rounded-t text-center overflow-hidden p-0 bg-gray-100 border border-1 border-black ml-4">
            <div class="w-1/2 h-full border-r cursor-pointer" :class="{'bg-orange-200' : this.uni == 'meunerie'}" v-on:click="this.changeUni('meunerie')">Meunerie</div>
            <div class="w-1/2 h-full cursor-pointer" :class="{'bg-orange-200' : this.uni == 'boulangerie'}" v-on:click="this.changeUni('boulangerie')">Boulangerie</div>
        </div>
      <div class="relative flex">
        <div
          class="flex overflow-y-scroll h-[84vh] min-w-[375px] border-b-2 border-t-2 bg-gray-200 ml-4 px-1  border-gray-500"
          ref="scrollZone"

        >
          <div class="h-full w-full">
            <p v-if="!this.videos.length" class="mt-20 w-full text-center text-gray-500">Aucune vidéo n'a été sauvegardé</p>
            <div
              v-for="(video, index) in videos"
              :key="video._id"
              class="vidd min-w-[350px] bg-white my-2 border border-gray-500 flex rounded overflow-hidden mx-2 flex cursor-pointer hover:tranform hover:scale-105 transition duration-300 ease-in-out"

            >
              <div class="flex ">
                <div class="overflow-hidden w-[292px] max-h-40 relative">
                  <img :src="video.thumbnailUrl.high.url" alt="Video Thumbnail" class="w-full bg-gray-500 absolute -top-8" />
                  <div class="absolute top-0 left-0 w-full h-full bg-black/70 flex flex-col justify-around opacity-0">
                    <a :href="video.link" target="_blank" class="font-bold text-gray-200 w-full text-center">Voir la vidéo</a>
                  </div>
                </div>
              </div>
              <div class="flex flex-col justify-between py-2 px-1">
                <svg v-on:click="changePlace(index, -1)" v-if="index != 0" viewBox="0 0 24 24" class="w-full h-12" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 11L12 8M12 8L9 11M12 8V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <div v-else class="w-full h-12"></div>
                <svg v-on:click="deleteVideo(video._id)" class="p-2 w-full h-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <svg v-on:click="changePlace(index, +1)" v-if="index != this.videos.length-1" class="w-full h-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 13L12 16M12 16L15 13M12 16V8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <div v-else class="w-full h-12"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-7/12">
          <div class="ml-12 w-full bg-white p-4 border border-gray-500 rounded-xl">
            <h2 class="text-xl font-bold">Ajouter une vidéo</h2>
            <div class="w-full mt-6">
              <div class="w-full h-44 flex justify-around bg-black rounded">
                <div class="overflow-hidden w-80 h-full relative bg-gray-400 ">
                  <img v-if="this.preview && this.preview != 'http://img.youtube.com/vi/null/hqdefault.jpg'" :src="this.preview" alt="Video Thumbnail" class="w-full min-w-[310px] min-h-44 w-full bg-gray-500 absolute -top-[30px]"  />
                  <div class="h-full flex flex-col justify-around">
                    <p class="w-full text-center text-gray-100">Aucune vidéo detecté</p>
                  </div>
                </div>
              </div>
              <div class="w-full px-12 flex flex-col justify-around py-10 ">
                <label class="text-sm" for="">Url de la vidéo</label>
                <input v-model="addUrl" @input="this.fetchPreviewThumbnail()" class="pl-2 border border-gray-500 rounded" type="text">
                <div v-on:click="addVideo()" v-if="preview != 'http://img.youtube.com/vi/null/hqdefault.jpg' && preview" class="text-center mt-4 border border-gray-500 bg-green-50 rounded cursor-pointer">Ajouter la vidéo</div>
                <div v-else  class="text-center mt-4 border border-gray-500 bg-red-50 rounded cursor-not-allowed">Veuillez renseigner un lien Youtube valide</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!loaded" id="loader" class="flex justify-center pt-80 top-0 w-[100vw] -mt-8 bg-black/60 z-[1000] absolute h-[110vh]">
              <div class="text-center">
                <span class="loader"></span>
                <h1 class="font-bold text-white">Chargement des informations...</h1>
              </div>
            </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import generate from 'youtube-thumbnail';

const axios = require("axios");
export default {
  name: "AdminVideoView",
  components: {
    SideBar,
  },
  data() {
    return {
      videos: [],
      scrollingx: 0,
      addUrl: "",
      preview: "",
      videoss: [],
      uni: "meunerie",
      synchro: false,
      loaded: false
    };
  },

  beforeCreate() {
    const token = localStorage.getItem('authToken');

      axios.get(this.$parent.$parent.ipReq + '/api/user/verif', {
        headers: {
            Authorization: `${token}`
        }
        })
        .then(() => {
        console.log("logged");
        })
        .catch(() => {
        this.$router.push("/login");
        });

    },

  mounted() {
    this.synchro = false;
    this.refreshVideos();
  },

  methods: {
    changePlace(id, place) {
      var temp = this.videos[id];
      this.videos[id] = this.videos[id+place];
      this.videos[id+place] = temp;
      this.upVideoOrder();
      
    },

    changeUni(uni){
      this.uni = uni;
      this.refreshVideos();
    },

    refreshVideos(){
      axios
      .get(this.$parent.$parent.ipReq + "/api/videos/" + this.uni, {
        headers: { Authorization: `${localStorage.getItem("authToken")}` },
      })
      .then(async (response) => {
        for (const video of response.data) {
          video.thumbnailUrl = await this.fetchVideoThumbnail(video.link);
          console.log(video.thumbnailUrl);
        }

        this.videos = response.data;
        this.synchro = true;
        if(this.loaded == false){
          this.loaded = true;
        }
      })
      .catch((err) => {
        console.log("error" + err);
      });
    },
    

    deleteVideo(id) {
      this.synchro = false;
      axios
        .delete(this.$parent.$parent.ipReq + "/api/videos/" + id, {
          headers: { Authorization: `${localStorage.getItem("authToken")}` },
        })
        .then((response) => {
          this.synchro = true;
          this.refreshVideos();
          console.log(response);
        })
        .catch((err) => {
          console.log("error" + err);
        });
    },

    async fetchVideoThumbnail(videoLink) {
      try {
        const thumbnailUrl = await generate(videoLink);
        return thumbnailUrl;
      } catch (error) {
        console.error('Error fetching video thumbnail:', error);
        return null;
      }
    },

    async fetchPreviewThumbnail() {

        // eslint-disable-next-line no-useless-escape
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(.*\/)?|(youtu\.be\/))([^\?&"'>]+)/;

      if (!youtubeRegex.test(this.addUrl)) { //permet de ne pas faire de requete si le contenu de l'input n'est pas un lien youtube
        this.preview = "";
        return null; // Retourne null si le lien n'est pas valide
      }

      try {
        const thumbnailUrl = await generate(this.addUrl);
        //when the image is loaded this preview take the link of the image
        this.preview = thumbnailUrl.high.url;
        return thumbnailUrl;
      } catch (error) {
        this.preview = "";
        console.error('Error fetching video thumbnail:', error);
        return null;
      }
    },


    upVideoOrder(){
      this.synchro = false;

        //create an array of ids in order of videos array
        var ids = [];
        for (const video of this.videos) {
          ids.push(video._id);
        }
        //send the array to the server
        axios
          .patch(this.$parent.$parent.ipReq + "/api/videos/order", {
            ids: ids,
            univers: this.uni
          }, {
            headers: { Authorization: `${localStorage.getItem("authToken")}` },
          })
          .then((response) => {
            this.synchro = true;
            console.log(response);
          })
          .catch((err) => {
            console.log("error" + err);
          });

    },


    addVideo(){
      
      this.synchro = false;
      //add using axios
      axios
        .post(this.$parent.$parent.ipReq + "/api/videos", {
          link: this.addUrl,
          univers: this.uni,
          enabled: true
        }, {
          headers: { Authorization: `${localStorage.getItem("authToken")}` },
        })
        .then(async (response) => {
          response.data.thumbnailUrl = await this.fetchVideoThumbnail(response.data.link);
          //add the video to the array
          this.videos.push(response.data);
          //reset the input
          this.addUrl = "";
          this.preview = "";
          this.synchro = true;
        })
        .catch((err) => {
          console.log("error" + err);
        });

    },



    getYouTubeID(url) {
      // Extraire l'ID de la vidéo à partir de l'URL
      var videoID = "";

      // Recherche de motifs d'URL courants
      var patterns = [
        // eslint-disable-next-line no-useless-escape
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
        // eslint-disable-next-line no-useless-escape
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
      ];

      patterns.forEach(function (pattern) {
        var match = url.match(pattern);
        if (match && match[1]) {
          videoID = match[1];
        }
      });

      return videoID;
    }
  },
};
</script>

<style scoped>
.vidd div div div {
  transition: 0.1s ease-in-out;
  opacity: 0;

}
.vidd div:hover div div {
  opacity: 1;
  transition: 0.1s ease-in-out;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

</style>
